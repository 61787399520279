.card__iframe iframe{
  width: 250px;
  height: 250px;
  display: flex;
}

.card__title{
  font-size: 16px;
}

.card__picture{
  transition: all 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
  border: 5px solid #1f2937;
  cursor: pointer;

}

.card__button{
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.card__picture:hover{
  box-shadow: 0 0 10px #1f2937;
}

