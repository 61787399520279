@font-face {
  font-family: 'mugwort_maximumregular';
  src: url('./font/mugwort_maximum-webfont.woff2') format('woff2'),
       url('./font/mugwort_maximum-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'Oswald';
  src: url('./font/Oswald-VariableFont_wght.woff2') format('woff2'),
      url('./font/Oswald-VariableFont_wght.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

.box__shadow {
  box-shadow: 0px 0px 5px black;
}

.title__beats {
  font-family: 'mugwort_maximumregular';
  letter-spacing: 0.05rem;
  font-size: 3rem;
}

.font__nice{
  font-family: 'mugwort_maximumregular';
}

.font__oswald{
  font-family: 'Oswald';
}