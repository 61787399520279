.home__container {
    padding-top: 80px;
    text-align: center;
}

.home__container h1{
    font-size: 4rem;
}

.home__image{
    /*background-image: url('../img/bg.jpg');*/
    background-image: url('../img/Maté Beats - TDF Vol.5 Banniere Tracklist Date.jpg');
    background-repeat: no-repeat;
    background-position: center;
    height: 300px;
    width: 300px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    background-size: cover;
    border: 5px solid #1f2937;
}

.icon__social{
    transition: all 2s ease-in-out;
    height: 2.5rem;
    width: 2.5rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
    box-shadow: 0 0 13px black;
}

.icon__social:hover{
    filter: hue-rotate(360deg);
}

@media all and (min-width: 1280px){
    .home__image{
        height: 500px;

    }
}



@media all and (min-width: 768px){
    .home__image{
        height: 500px;
        width: 500px;
    }
    
}
@media all and (min-width: 1024px){
    .home__image{
        height: 600px;
        width: 100%;
    }
}

@media all and (max-width: 1024px) {
    .home__image{
        background-image: url('../img/Maté Beats - TDF Vol.5 Cover.png');

    }
}
